import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '../index'
import { getPayload } from '@/utils/jwt'
import { User } from '@/models/user.ts'
import { Plan } from '@/models/plan'

@Module({
  name: 'auth',
  dynamic: true,
  namespaced: true,
  store
})
export default class AuthStoreModule extends VuexModule {
    private _token?: string
    private _user?: User
    private _currentPlanId?: number
    private _isFirst?: boolean

    get token (): string | undefined {
      if (!this._token) {
        const tokenStr = localStorage.getItem('token')
        if (tokenStr) {
          this._token = tokenStr
        }
      }
      return this._token
    }

    get isTokenValid (): boolean {
      return !!this.user && (this.user.exp * 1000 > Date.now())
    }

    get user (): User | undefined {
      if (!this._user) {
        const userStr = localStorage.getItem('user')
        if (userStr) {
          this._user = JSON.parse(userStr) as User
        }
      }
      return this._user
    }

    get avatar (): string {
      if (this.user) {
        return this.user.avatar
      }
      return 'http://a-news.oss-cn-hangzhou.aliyuncs.com/avatar/1.jpg'
    }

    get userId (): string {
      if (this.user) {
        return this.user.userId
      }
      return ''
    }

    get name (): string {
      if (this.user) {
        return this.user.name
      }
      return ''
    }

    get phone (): string {
      if (this.user) {
        return this.user.phone
      }
      return ''
    }

    get isHavePlan (): boolean {
      const currentPlanId = localStorage.getItem('currentPlanId')
      return !!currentPlanId && !this._isFirst
    }

    get planId (): number {
      const planId = localStorage.getItem('teacherPlanId')
      console.log('teacherPlanId ===== ', planId)
      if (this._currentPlanId) {
        return this._currentPlanId
      } else if (planId) {
        return parseInt(planId)
      }
      return 0
    }

    get isLogin (): boolean {
      return !!this.user?.name
    }

    get isTeacher (): boolean {
      // return true
      if (this.user) {
        const spArr = this.user.roles.split(',')
        const arr = spArr.filter((item: any) => item === 'MINDUP__teacher')
        if (arr.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }

    @Mutation
    setToken (value: string) {
      const payload = getPayload(value)
      this._token = value
      this._user = JSON.parse(payload)
      localStorage.setItem('token', value)
      localStorage.setItem('user', JSON.stringify(this._user))
    }

    @Mutation
    setUser (user: User) {
      this._user = user
    }

    @Mutation
    setPlanId (data: number) {
      if (data) {
        localStorage.setItem('teacherPlanId', data + '')
        this._currentPlanId = data
      } else {
        localStorage.setItem('teacherPlanId', '')
        this._currentPlanId = undefined
      }
    }

    @Mutation
    setCurrentPlanId (data: string) {
      if (data) {
        localStorage.setItem('currentPlanId', data)
      }
    }

    @Mutation
    setIsFirst (data: boolean) {
      if (data) {
        this._isFirst = data
      }
    }

    @Mutation
    removeToken () {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
    }
}

export const AuthStore = getModule(AuthStoreModule)
