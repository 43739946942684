import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import {
  ActionSheet,
  ActionBar,
  ActionBarIcon,
  Switch,
  Popup,
  Icon,
  Row,
  Col,
  Button,
  Tabbar,
  TabbarItem,
  Grid,
  GridItem,
  Image as VanImage,
  Toast as VanToast,
  List,
  NoticeBar,
  Overlay,
  Rate,
  Dialog,
  Notify,
  ImagePreview,
  Tab, Tabs, NavBar, Radio, RadioGroup, Cell, CellGroup, Checkbox, CheckboxGroup, Uploader, Calendar, Slider, Field, Picker, Badge, PullRefresh, Collapse, CollapseItem
} from 'vant'
import 'vant/lib/index.css'
import './styles/main.scss'
import 'default-passive-events'

const app = createApp(App)
app.use(Button).use(Tabbar).use(TabbarItem).use(Overlay).use(Row).use(Icon).use(NoticeBar).use(Switch).use(Grid).use(GridItem).use(ActionSheet).use(ActionBar).use(ActionBarIcon).use(Popup).use(Col).use(VanImage).use(VanToast).use(List).use(store).use(router).use(Rate).use(Dialog).use(Notify).use(ImagePreview).use(Tab).use(Tabs).use(NavBar)
app.use(Radio)
app.use(RadioGroup)
app.use(Cell)
app.use(CellGroup)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(Uploader)
app.use(Calendar)
app.use(Slider)
app.use(Field)
app.use(Picker)
app.use(Badge)
app.use(PullRefresh)
app.use(Collapse)
app.use(CollapseItem)

app.directive('preventReClick', {
  // 当被绑定的元素插入到 DOM 中时……
  mounted (el: any, binding:any) {
    console.log(el)
    console.log(binding)
    console.log('自定义局部指令注册成功！')
    el.addEventListener('click', () => {
      if (!el.disabled) {
        console.log('disable')
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 3000)
      }
    })
  }
})

app.use(store).use(router).mount('#app')
