
import { Options, Vue } from 'vue-class-component'
import { AuthStore } from '../store/modules/auth'
import wx from 'weixin-js-sdk-ts'

@Options({
  components: {}
})
export default class UserHeader extends Vue {
  authStore = AuthStore

  tapHeader () {
    if (this.authStore.name === undefined || this.authStore.name === 'string') {
      wx.miniProgram.navigateTo({ url: '/pages/login/login' })
    } else {
      this.$router.push({ name: 'UserInfo' })
    }
  }
}
