
import { Options, Vue } from 'vue-class-component'
import UserHeader from '@/components/UserHeader.vue'
import service from '@/utils/request'
import { AuthStore } from '@/store/modules/auth'
import wx from 'weixin-js-sdk-ts'

@Options({
  components: {
    UserHeader
  }
})
export default class Home extends Vue {
  currentIndex = 0
  isHeartShow = true
  unreadMessageNum = 0
  applyNum = 0
  authStore = AuthStore

  get isHavePlan () {
    return this.authStore.isHavePlan
  }

  get isTeacher () {
    return this.authStore.isTeacher
  }

  mounted () {
    const path = this.$route.path
    if (path === '/information') {
      this.currentIndex = 3
    } else if (path === '/collect') {
      this.currentIndex = 2
    } else if (path === '/user-info') {
      this.currentIndex = 4
    } else if (path === '/plan') {
      this.currentIndex = 0
    } else if (path === '/practice') {
      this.currentIndex = 1
    }
    this.loadUnreadMessage()
    if (this.isTeacher) {
      this.loadNewApplyNum()
    }

    if (this.isHavePlan) {
      this.$router.push({ name: 'Practice' })
      this.currentIndex = 1
      this.authStore.setIsFirst(true)
    }
  }

  tapTabbar (index: number) {
    this.currentIndex = index
    // if (index === 3) {
    //   // this.updateUnreadMessage()
    // } else {
    //   this.loadUnreadMessage()
    // }
    this.loadUnreadMessage()
  }

  loadUnreadMessage () {
    service.get('/mindup/mini/messages/unread-message-num/get', {}).then(res => {
      this.unreadMessageNum = res.data
    })
  }

  loadNewApplyNum () {
    service.get('/mindup/mini/plans/current-teacher-plan-apply-num', {}).then(res => {
      this.applyNum = res.data
    })
  }

  updateUnreadMessage () {
    service.post('/mindup/mini/messages/all-message/set-read', {}).then(res => {
      this.unreadMessageNum = res.data
    })
  }
}
