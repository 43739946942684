import axios from 'axios'
import { AuthStore } from '@/store/modules/auth'
import { Toast } from 'vant'

const service = axios.create({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  baseURL: window.VUE_APP_BASE_API,
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (AuthStore.token) {
      config.headers.Authorization = AuthStore.token + ''
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    console.log('开始请求url==', window.VUE_APP_BASE_API + config.url)
    if (config.method === 'get' && config.params) {
      console.log('param==', config.params)
    } else if (config.method === 'post') {
      console.log('param==', config.data)
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

  /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
  response => {
    console.log('返回数据 == ', response.config.url)
    console.log(response.data.data)
    return response.data
  },
  error => {
    console.log('error  ====  ', error)
    const status = error.response.status
    if (status === 401) {
      Toast.fail('认证失败，请重新登录').then(() => {
        AuthStore.removeToken()
        window.location.reload()
      })
    } else {
      Toast.fail(error.response.data.msg || '发生错误，请联系开发团队')
      return Promise.reject(error.response.data)
    }
  }
)

export default service
