import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import request from '@/utils/request'
import { AuthStore } from '@/store/modules/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '正念之树'
    },
    component: Home,
    redirect: '/plan',
    children: [
      {
        path: '/practice',
        name: 'Practice',
        meta: {
          title: '正念之树'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Practice.vue')
      },
      {
        path: '/information',
        name: 'Information',
        meta: {
          title: '正念之树'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Information.vue')
      },
      {
        path: '/plan',
        name: 'Plan',
        meta: {
          title: '正念之树'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Plan.vue')
      },
      {
        path: '/collect',
        name: 'Collect',
        meta: {
          title: '正念之树'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Collect.vue')
      },
      {
        path: '/user-info',
        name: 'UserInfo',
        meta: {
          title: '我的'
        },
        component: () => import('../components/UserInfo.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/plan-select',
    name: 'PlanSelect',
    meta: {
      title: '计划选择'
    },
    component: () => import('../components/PlanSelect.vue')
  },
  {
    path: '/plan-detail/:planId',
    name: 'PlanDetail',
    meta: {
      title: '计划详情'
    },
    component: () => import('../components/PlanDetail.vue')
  },
  {
    path: '/plan-teacher-detail/:planId',
    name: 'PlanTeacherDetail',
    meta: {
      title: '计划详情'
    },
    component: () => import('../components/PlanTeacherDetail.vue')
  },
  {
    path: '/plan-detail-info/:planId',
    name: 'PlanDetailInfo',
    meta: {
      title: '计划详情'
    },
    component: () => import('../components/PlanDetailInfo.vue')
  },
  {
    path: '/plan-finish',
    name: 'PlanFinish',
    meta: {
      title: '结束计划'
    },
    component: () => import('../components/PlanFinish.vue')
  },
  {
    path: '/standard-practice/:practiceId/:taskId',
    name: 'StandardPractice',
    meta: {
      title: '正式练习'
    },
    component: () => import('../components/StandardPractice.vue')
  },
  {
    path: '/practice-evaluate',
    name: 'PracticeEvaluate',
    meta: {
      title: '练习评价'
    },
    component: () => import('../components/PracticeEvaluate.vue')
  },
  {
    path: '/diary-write',
    name: 'DiaryWrite',
    meta: {
      title: '写日记'
    },
    component: () => import('../components/DiaryWrite.vue')
  },
  {
    path: '/life-practice/:taskId/:practiceId',
    name: 'LifePractice',
    meta: {
      title: '生活练习'
    },
    component: () => import('../components/LifePractice.vue')
  },
  {
    path: '/practice-test/:surveyId/:taskId',
    name: 'PracticeTest',
    meta: {
      title: '练习测试'
    },
    component: () => import('../components/PracticeTest.vue')
  },
  {
    path: '/practice-complete',
    name: 'PracticeComplete',
    meta: {
      title: '练习完成'
    },
    component: () => import('../components/PracticeComplete.vue')
  },
  {
    path: '/information-detail/:noteId',
    name: 'InformationDetail',
    meta: {
      title: '消息详情'
    },
    component: () => import('../components/InformationDetail.vue')
  },
  {
    path: '/student-management/:planId',
    name: 'StudentManagement',
    meta: {
      title: '学员管理'
    },
    component: () => import('../components/StudentManagement.vue')
  },
  {
    path: '/plan-change',
    name: 'PlanChange',
    meta: {
      title: '计划管理'
    },
    component: () => import('../components/PlanChange.vue')
  },
  {
    path: '/plan-activate',
    name: 'PlanActivate',
    meta: {
      title: '激活计划'
    },
    component: () => import('../components/PlanActivate.vue')
  },
  {
    path: '/plan-list',
    name: 'PlanList',
    meta: {
      title: '计划列表'
    },
    component: () => import('../components/PlanList.vue')
  },
  {
    path: '/plan-practice-list/:planId',
    name: 'PlanPracticeList',
    meta: {
      title: '计划练习'
    },
    component: () => import('../components/PlanPracticeList.vue')
  },
  {
    path: '/student-apply/:planId',
    name: 'StudentApply',
    meta: {
      title: '学员管理'
    },
    component: () => import('../components/StudentApply.vue')
  },
  {
    path: '/practice-daily-list',
    name: 'PracticeDailyList',
    meta: {
      title: '添加每日练习'
    },
    component: () => import('../components/PracticeDailyList.vue')
  },
  {
    path: '/user-detail/:userId/:planId',
    name: 'UserDetail',
    meta: {
      title: '用户详情'
    },
    component: () => import('../components/UserDetail.vue')
  },
  {
    path: '/rank',
    name: 'Rank',
    meta: {
      title: 'rank'
    },
    component: () => import('../views/Rank.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const whiteList = ['/login', '/']

const authorizeToken = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  document.title = to.meta.title + ''

  const tokenParam = to.query.token
  const code = to.query.code
  // const appId = 'wx240c17963686d825'
  const appId = 'wx8dbaae90f2d8a3a3'
  if (tokenParam) {
    AuthStore.setToken(tokenParam + '')
    next()
  } else if (code) {
    request.post('uaa/public/wx/login', {
      appId: appId,
      code: code
    }).then((res: any) => {
      AuthStore.setToken(res.data.token + '')
      next()
    })
  } else {
    const token = AuthStore.token
    if (token && AuthStore.isTokenValid) {
      if (to.path === '/login') {
        next({ path: '/' })
      } else {
        next()
      }
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        next()
      } else {
        next(`/login?redirect=${to.path}`)
      }
    }
  }
}

router.beforeEach(authorizeToken)
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  if (isChunkLoadFailed) {
    // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
    location.reload()
    // const targetPath = router.history.pending.fullPath
    // $router.replace(targetPath);
  }
})

export default router
